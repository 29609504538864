import { useAuth } from '@pesto/features';
import { LoginScreen } from '@pesto/ui/features/LoginScreen/LoginScreen';
import { useNavigate } from 'react-router-dom';

import logo from '../assets/PestoLogoRed.svg';

export const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated, sendSmsCode, verifySmsCode, codeLoading } = useAuth();
  const handleEmailSubmit = (phoneNumber: string) => {
    return sendSmsCode(phoneNumber)
      .then(() => {})
      .catch((err: { description: string }) => {
        console.error('Error sending passcode:', err);
      });
  };

  const handleVerificationCodeSubmit = (phoneNumber: string, code: string) => {
    return verifySmsCode(phoneNumber, code)
      .then(() => navigate('/'))
      .catch((err: { description: string }) => {
        console.error('Error parsing hash:', err);
      });
  };

  return (
    <LoginScreen
      logo={logo}
      title="Partner Control"
      codeLoading={codeLoading}
      isAuthenticated={isAuthenticated}
      navigate={navigate}
      sendSmsCode={handleEmailSubmit}
      verifySmsCode={handleVerificationCodeSubmit}
    />
  );
};
