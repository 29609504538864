import { Button, Typography } from '@pesto/ui';
import { Switch } from '@pesto/ui/components/Switch';
import { EditIcon } from 'lucide-react';
import type { ReactNode } from 'react';
import { useState } from 'react';

import type { GetUserByIdQuery } from '../../../../__generated__/graphql/api';
import { UserDetailsCard } from '../UserDetailsCard';

import type { QuickActionModalType } from './QuickActionModal';
import { QuickActionModal, USER_QUALITY } from './QuickActionModal';

interface UserFraudQualityProps {
  user: GetUserByIdQuery['user'];
}

interface ModalState {
  isOpen: boolean;
  type: QuickActionModalType;
}

const UserQuickActionsRow = (props: { children: ReactNode }) => {
  return <div className={'flex items-center gap-4'}>{props.children}</div>;
};
export const UserQuickActionsContainer = (props: UserFraudQualityProps) => {
  const { user } = props;
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    type: null,
  });

  const handleToggleFamAndFriends = () => {
    return null;
  };

  const quality = (
    <UserQuickActionsRow>
      <Typography variant={'body'}>Quality:</Typography>
      <Typography variant={'body'}>
        {user?.quality !== null
          ? Object.keys(USER_QUALITY).find(key => USER_QUALITY[key as keyof typeof USER_QUALITY] === user?.quality)
          : 'no data'}
      </Typography>
      <Button
        variant={'outline'}
        size={'sm'}
        onClick={() =>
          setModalState({
            isOpen: true,
            type: 'quality',
          })
        }
      >
        <EditIcon />
      </Button>
    </UserQuickActionsRow>
  );

  const risk = (
    <UserQuickActionsRow>
      <Typography variant={'body'}>Risk:</Typography>
      <Typography variant={'body'}>
        {user?.risk !== null
          ? Object.keys(USER_QUALITY).find(key => USER_QUALITY[key as keyof typeof USER_QUALITY] === user?.risk)
          : 'no data'}
      </Typography>
      <Button
        variant={'outline'}
        size={'sm'}
        onClick={() =>
          setModalState({
            isOpen: true,
            type: 'risk',
          })
        }
      >
        <EditIcon />
      </Button>
    </UserQuickActionsRow>
  );

  const friendAndFamily = (
    <UserQuickActionsRow>
      <Typography variant={'body'}>Friends and family:</Typography>
      <Switch checked={user?.isFriendsAndFam || false} onChange={handleToggleFamAndFriends} />
    </UserQuickActionsRow>
  );

  return (
    <>
      <UserDetailsCard className={'w-full max-w-[350px]'}>
        <Typography variant={'headerSmall'}>Quick Actions</Typography>
        {quality}
        {risk}
        {friendAndFamily}
        <Button onClick={() => {}}>Identify User</Button>
      </UserDetailsCard>
      <QuickActionModal
        user={user}
        isOpen={modalState.isOpen}
        type={modalState.type}
        onClose={() => setModalState({ isOpen: false, type: null })}
      />
    </>
  );
};
