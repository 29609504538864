// noinspection JSUnusedGlobalSymbols

/**
 * This file was generated by the GraphQL Code Generator.
 * DO NOT EDIT.
 * @source: graphql-codegen/codegen.yml
 */

/* eslint-disable @typescript-eslint/no-use-before-define,@typescript-eslint/no-unused-vars,no-prototype-builtins,@typescript-eslint/consistent-type-imports,@typescript-eslint/ban-ts-comment */
// @ts-nocheck
import {
  Address,
  AdminActionRecord,
  Card,
  CreditApplication,
  CreditCheck,
  CreditOffer,
  DailyFile,
  DocCheck,
  Item,
  ItemCustodyRecord,
  ItemEvent,
  ListCardsResult,
  ListCreditApplicationsResult,
  ListStatementsResult,
  ListUsersResult,
  PaginationDetails,
  PaymentCheck,
  PaymentDetails,
  PaymentVerification,
  PhotoLink,
  RootQueryType,
  ScheduledPayment,
  Statement,
  User,
  AdminActionRecordType,
  AssetSubtype,
  AssetType,
  Brand,
  CreditApplicationStatus,
  CustodyType,
  DocCheckStatus,
  ItemEventType,
  PaymentCheckStatus,
  PaymentPreference,
  SecurityType,
  ShippingPreference,
  ShippingPriority,
  SortDirection,
  StatementStatus,
} from './api';

export const MockAddress = (overrides?: Partial<Address>, _relationshipsToOmit: Set<string> = new Set()): Address => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('Address');
  return {
    addressFirstLine:
      overrides && overrides.hasOwnProperty('addressFirstLine') ? overrides.addressFirstLine! : 'conventus',
    addressSecondLine:
      overrides && overrides.hasOwnProperty('addressSecondLine') ? overrides.addressSecondLine! : 'officia',
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'audacia',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'dd7f41ad-ccc5-47f4-b0a8-13ca22363cb0',
    isBilling: overrides && overrides.hasOwnProperty('isBilling') ? overrides.isBilling! : true,
    isConfirmed: overrides && overrides.hasOwnProperty('isConfirmed') ? overrides.isConfirmed! : false,
    isPayment: overrides && overrides.hasOwnProperty('isPayment') ? overrides.isPayment! : false,
    isPoBox: overrides && overrides.hasOwnProperty('isPoBox') ? overrides.isPoBox! : true,
    isShipping: overrides && overrides.hasOwnProperty('isShipping') ? overrides.isShipping! : false,
    state: overrides && overrides.hasOwnProperty('state') ? overrides.state! : 'eveniet',
    suiteNumber: overrides && overrides.hasOwnProperty('suiteNumber') ? overrides.suiteNumber! : 'cupressus',
    zip: overrides && overrides.hasOwnProperty('zip') ? overrides.zip! : 'repudiandae',
  };
};

export const MockAdminActionRecord = (
  overrides?: Partial<AdminActionRecord>,
  _relationshipsToOmit: Set<string> = new Set(),
): AdminActionRecord => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('AdminActionRecord');
  return {
    actor:
      overrides && overrides.hasOwnProperty('actor')
        ? overrides.actor!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
    creditApplication:
      overrides && overrides.hasOwnProperty('creditApplication')
        ? overrides.creditApplication!
        : relationshipsToOmit.has('CreditApplication')
          ? ({} as CreditApplication)
          : MockCreditApplication({}, relationshipsToOmit),
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'nostrum',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '6a53cc55-b1f4-4b68-b882-8293039740ec',
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : AdminActionRecordType.AcquirerChargeFee,
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
  };
};

export const MockCard = (overrides?: Partial<Card>, _relationshipsToOmit: Set<string> = new Set()): Card => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('Card');
  return {
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : false,
    creditLimit: overrides && overrides.hasOwnProperty('creditLimit') ? overrides.creditLimit! : 4547,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '7f6b6fd6-3f17-46bf-8539-a5c433d003c4',
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    referenceId: overrides && overrides.hasOwnProperty('referenceId') ? overrides.referenceId! : 'sophismata',
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : 'catena',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
  };
};

export const MockCreditApplication = (
  overrides?: Partial<CreditApplication>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreditApplication => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('CreditApplication');
  return {
    adminActionRecords:
      overrides && overrides.hasOwnProperty('adminActionRecords')
        ? overrides.adminActionRecords!
        : [
            relationshipsToOmit.has('AdminActionRecord')
              ? ({} as AdminActionRecord)
              : MockAdminActionRecord({}, relationshipsToOmit),
          ],
    creditChecks:
      overrides && overrides.hasOwnProperty('creditChecks')
        ? overrides.creditChecks!
        : [relationshipsToOmit.has('CreditCheck') ? ({} as CreditCheck) : MockCreditCheck({}, relationshipsToOmit)],
    creditOffers:
      overrides && overrides.hasOwnProperty('creditOffers')
        ? overrides.creditOffers!
        : [relationshipsToOmit.has('CreditOffer') ? ({} as CreditOffer) : MockCreditOffer({}, relationshipsToOmit)],
    creditTooLow: overrides && overrides.hasOwnProperty('creditTooLow') ? overrides.creditTooLow! : true,
    docChecks:
      overrides && overrides.hasOwnProperty('docChecks')
        ? overrides.docChecks!
        : [relationshipsToOmit.has('DocCheck') ? ({} as DocCheck) : MockDocCheck({}, relationshipsToOmit)],
    highCreditEstimate:
      overrides && overrides.hasOwnProperty('highCreditEstimate') ? overrides.highCreditEstimate! : 'venia',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '0aa9dda0-33d2-46b8-8690-aaad3d8e3707',
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    isSuspended: overrides && overrides.hasOwnProperty('isSuspended') ? overrides.isSuspended! : true,
    items:
      overrides && overrides.hasOwnProperty('items')
        ? overrides.items!
        : [relationshipsToOmit.has('Item') ? ({} as Item) : MockItem({}, relationshipsToOmit)],
    lowCreditEstimate:
      overrides && overrides.hasOwnProperty('lowCreditEstimate') ? overrides.lowCreditEstimate! : 'speciosus',
    offerAmount: overrides && overrides.hasOwnProperty('offerAmount') ? overrides.offerAmount! : 'quos',
    paymentChecks:
      overrides && overrides.hasOwnProperty('paymentChecks')
        ? overrides.paymentChecks!
        : [relationshipsToOmit.has('PaymentCheck') ? ({} as PaymentCheck) : MockPaymentCheck({}, relationshipsToOmit)],
    paymentDetails:
      overrides && overrides.hasOwnProperty('paymentDetails')
        ? overrides.paymentDetails!
        : relationshipsToOmit.has('PaymentDetails')
          ? ({} as PaymentDetails)
          : MockPaymentDetails({}, relationshipsToOmit),
    paymentPreference:
      overrides && overrides.hasOwnProperty('paymentPreference')
        ? overrides.paymentPreference!
        : PaymentPreference.NoneSelected,
    pestoUser:
      overrides && overrides.hasOwnProperty('pestoUser')
        ? overrides.pestoUser!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
    scheduledPayments:
      overrides && overrides.hasOwnProperty('scheduledPayments')
        ? overrides.scheduledPayments!
        : [
            relationshipsToOmit.has('ScheduledPayment')
              ? ({} as ScheduledPayment)
              : MockScheduledPayment({}, relationshipsToOmit),
          ],
    securityType: overrides && overrides.hasOwnProperty('securityType') ? overrides.securityType! : SecurityType.Cash,
    shippingPreference:
      overrides && overrides.hasOwnProperty('shippingPreference')
        ? overrides.shippingPreference!
        : ShippingPreference.Diy,
    shippingPriority:
      overrides && overrides.hasOwnProperty('shippingPriority')
        ? overrides.shippingPriority!
        : ShippingPriority.Expedited,
    status:
      overrides && overrides.hasOwnProperty('status') ? overrides.status! : CreditApplicationStatus.AppraisalInProgress,
    statusUpdatedAt:
      overrides && overrides.hasOwnProperty('statusUpdatedAt') ? overrides.statusUpdatedAt! : new Date().toISOString(),
    updatedAt: overrides && overrides.hasOwnProperty('updatedAt') ? overrides.updatedAt! : new Date().toISOString(),
    verifyDoc: overrides && overrides.hasOwnProperty('verifyDoc') ? overrides.verifyDoc! : true,
    verifyPayment: overrides && overrides.hasOwnProperty('verifyPayment') ? overrides.verifyPayment! : true,
  };
};

export const MockCreditCheck = (
  overrides?: Partial<CreditCheck>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreditCheck => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('CreditCheck');
  return {
    abilityToPay: overrides && overrides.hasOwnProperty('abilityToPay') ? overrides.abilityToPay! : true,
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : false,
    address: overrides && overrides.hasOwnProperty('address') ? overrides.address! : false,
    addressCorrelation:
      overrides && overrides.hasOwnProperty('addressCorrelation') ? overrides.addressCorrelation! : true,
    addressStrict: overrides && overrides.hasOwnProperty('addressStrict') ? overrides.addressStrict! : false,
    alertlist: overrides && overrides.hasOwnProperty('alertlist') ? overrides.alertlist! : false,
    bankruptcy: overrides && overrides.hasOwnProperty('bankruptcy') ? overrides.bankruptcy! : true,
    creditFreeze: overrides && overrides.hasOwnProperty('creditFreeze') ? overrides.creditFreeze! : true,
    decision: overrides && overrides.hasOwnProperty('decision') ? overrides.decision! : 'super',
    deviceDuplicate: overrides && overrides.hasOwnProperty('deviceDuplicate') ? overrides.deviceDuplicate! : false,
    deviceIdentity: overrides && overrides.hasOwnProperty('deviceIdentity') ? overrides.deviceIdentity! : false,
    deviceOne: overrides && overrides.hasOwnProperty('deviceOne') ? overrides.deviceOne! : true,
    deviceThree: overrides && overrides.hasOwnProperty('deviceThree') ? overrides.deviceThree! : false,
    deviceTwo: overrides && overrides.hasOwnProperty('deviceTwo') ? overrides.deviceTwo! : true,
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : false,
    emailCorrelation: overrides && overrides.hasOwnProperty('emailCorrelation') ? overrides.emailCorrelation! : false,
    emailStrict: overrides && overrides.hasOwnProperty('emailStrict') ? overrides.emailStrict! : true,
    fraud: overrides && overrides.hasOwnProperty('fraud') ? overrides.fraud! : false,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '122e59c0-4e79-4efc-93ab-e3f34e309a2a',
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    kyc: overrides && overrides.hasOwnProperty('kyc') ? overrides.kyc! : false,
    mla: overrides && overrides.hasOwnProperty('mla') ? overrides.mla! : false,
    noSsnMatch: overrides && overrides.hasOwnProperty('noSsnMatch') ? overrides.noSsnMatch! : true,
    ofac: overrides && overrides.hasOwnProperty('ofac') ? overrides.ofac! : false,
    pep: overrides && overrides.hasOwnProperty('pep') ? overrides.pep! : true,
    phone: overrides && overrides.hasOwnProperty('phone') ? overrides.phone! : true,
    phoneCorrelation: overrides && overrides.hasOwnProperty('phoneCorrelation') ? overrides.phoneCorrelation! : true,
    phoneStrict: overrides && overrides.hasOwnProperty('phoneStrict') ? overrides.phoneStrict! : false,
    reason: overrides && overrides.hasOwnProperty('reason') ? overrides.reason! : 'ventito',
    socureIsNull: overrides && overrides.hasOwnProperty('socureIsNull') ? overrides.socureIsNull! : true,
    synthetic: overrides && overrides.hasOwnProperty('synthetic') ? overrides.synthetic! : true,
    syntheticStrict: overrides && overrides.hasOwnProperty('syntheticStrict') ? overrides.syntheticStrict! : false,
    transunionIsNull: overrides && overrides.hasOwnProperty('transunionIsNull') ? overrides.transunionIsNull! : false,
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : 'barba',
    watchlist: overrides && overrides.hasOwnProperty('watchlist') ? overrides.watchlist! : true,
  };
};

export const MockCreditOffer = (
  overrides?: Partial<CreditOffer>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreditOffer => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('CreditOffer');
  return {
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : false,
    decision: overrides && overrides.hasOwnProperty('decision') ? overrides.decision! : 'cumque',
    decisionAt: overrides && overrides.hasOwnProperty('decisionAt') ? overrides.decisionAt! : new Date().toISOString(),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'c53760be-ab68-4e90-9166-f184041abc03',
    reason: overrides && overrides.hasOwnProperty('reason') ? overrides.reason! : 'curia',
  };
};

export const MockDailyFile = (
  overrides?: Partial<DailyFile>,
  _relationshipsToOmit: Set<string> = new Set(),
): DailyFile => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('DailyFile');
  return {
    addressLine1: overrides && overrides.hasOwnProperty('addressLine1') ? overrides.addressLine1! : 'clam',
    addressLine2: overrides && overrides.hasOwnProperty('addressLine2') ? overrides.addressLine2! : 'curis',
    card:
      overrides && overrides.hasOwnProperty('card')
        ? overrides.card!
        : relationshipsToOmit.has('Card')
          ? ({} as Card)
          : MockCard({}, relationshipsToOmit),
    cardReferenceNumber:
      overrides && overrides.hasOwnProperty('cardReferenceNumber') ? overrides.cardReferenceNumber! : 'cinis',
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'vero',
    details: overrides && overrides.hasOwnProperty('details') ? overrides.details! : 'accendo',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'eius',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'expedita',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '05c3932a-d0e4-4e21-98f6-af6ee8edea20',
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    lastName: overrides && overrides.hasOwnProperty('lastName') ? overrides.lastName! : 'desino',
    pastDueStatus: overrides && overrides.hasOwnProperty('pastDueStatus') ? overrides.pastDueStatus! : 'thorax',
    primaryPhoneNumber:
      overrides && overrides.hasOwnProperty('primaryPhoneNumber') ? overrides.primaryPhoneNumber! : 'pariatur',
    state: overrides && overrides.hasOwnProperty('state') ? overrides.state! : 'carbo',
    updatedAt: overrides && overrides.hasOwnProperty('updatedAt') ? overrides.updatedAt! : new Date().toISOString(),
    zipCode: overrides && overrides.hasOwnProperty('zipCode') ? overrides.zipCode! : 'molestiae',
  };
};

export const MockDocCheck = (
  overrides?: Partial<DocCheck>,
  _relationshipsToOmit: Set<string> = new Set(),
): DocCheck => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('DocCheck');
  return {
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : false,
    documentId: overrides && overrides.hasOwnProperty('documentId') ? overrides.documentId! : 'ambitus',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '996d828f-3106-4337-a9b5-00bf2b75fff6',
    reason: overrides && overrides.hasOwnProperty('reason') ? overrides.reason! : 'molestias',
    reviewedBy:
      overrides && overrides.hasOwnProperty('reviewedBy')
        ? overrides.reviewedBy!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : DocCheckStatus.Approved,
  };
};

export const MockItem = (overrides?: Partial<Item>, _relationshipsToOmit: Set<string> = new Set()): Item => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('Item');
  return {
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : true,
    brand: overrides && overrides.hasOwnProperty('brand') ? overrides.brand! : Brand.AudemarsPiguet,
    creditApplication:
      overrides && overrides.hasOwnProperty('creditApplication')
        ? overrides.creditApplication!
        : relationshipsToOmit.has('CreditApplication')
          ? ({} as CreditApplication)
          : MockCreditApplication({}, relationshipsToOmit),
    creditValue: overrides && overrides.hasOwnProperty('creditValue') ? overrides.creditValue! : 6825,
    creditValueFormatted:
      overrides && overrides.hasOwnProperty('creditValueFormatted') ? overrides.creditValueFormatted! : 'calculus',
    deletedAt: overrides && overrides.hasOwnProperty('deletedAt') ? overrides.deletedAt! : new Date().toISOString(),
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'apparatus',
    finalCreditValue: overrides && overrides.hasOwnProperty('finalCreditValue') ? overrides.finalCreditValue! : 5063,
    finalCreditValueOverride:
      overrides && overrides.hasOwnProperty('finalCreditValueOverride') ? overrides.finalCreditValueOverride! : 7922,
    finalDetails: overrides && overrides.hasOwnProperty('finalDetails') ? overrides.finalDetails! : 'defaeco',
    finalLtv: overrides && overrides.hasOwnProperty('finalLtv') ? overrides.finalLtv! : 4522,
    finalValuation: overrides && overrides.hasOwnProperty('finalValuation') ? overrides.finalValuation! : 2772,
    highCreditEstimate:
      overrides && overrides.hasOwnProperty('highCreditEstimate') ? overrides.highCreditEstimate! : 'desino',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '00ede227-1e16-4204-ba37-575abe68b2a7',
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    isActive: overrides && overrides.hasOwnProperty('isActive') ? overrides.isActive! : true,
    itemCustodyRecords:
      overrides && overrides.hasOwnProperty('itemCustodyRecords')
        ? overrides.itemCustodyRecords!
        : [
            relationshipsToOmit.has('ItemCustodyRecord')
              ? ({} as ItemCustodyRecord)
              : MockItemCustodyRecord({}, relationshipsToOmit),
          ],
    itemEvents:
      overrides && overrides.hasOwnProperty('itemEvents')
        ? overrides.itemEvents!
        : [relationshipsToOmit.has('ItemEvent') ? ({} as ItemEvent) : MockItemEvent({}, relationshipsToOmit)],
    lowCreditEstimate:
      overrides && overrides.hasOwnProperty('lowCreditEstimate') ? overrides.lowCreditEstimate! : 'demo',
    needsReview: overrides && overrides.hasOwnProperty('needsReview') ? overrides.needsReview! : false,
    otherAssetType: overrides && overrides.hasOwnProperty('otherAssetType') ? overrides.otherAssetType! : 'temperantia',
    pestoUser:
      overrides && overrides.hasOwnProperty('pestoUser')
        ? overrides.pestoUser!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
    photoLinks:
      overrides && overrides.hasOwnProperty('photoLinks')
        ? overrides.photoLinks!
        : [relationshipsToOmit.has('PhotoLink') ? ({} as PhotoLink) : MockPhotoLink({}, relationshipsToOmit)],
    presignedPhotoLinks:
      overrides && overrides.hasOwnProperty('presignedPhotoLinks') ? overrides.presignedPhotoLinks! : ['volaticus'],
    qrCode: overrides && overrides.hasOwnProperty('qrCode') ? overrides.qrCode! : 'desparatus',
    quickCreditValue: overrides && overrides.hasOwnProperty('quickCreditValue') ? overrides.quickCreditValue! : 3757,
    quickCreditValueOverride:
      overrides && overrides.hasOwnProperty('quickCreditValueOverride') ? overrides.quickCreditValueOverride! : 8008,
    quickDetails: overrides && overrides.hasOwnProperty('quickDetails') ? overrides.quickDetails! : 'amoveo',
    quickLtv: overrides && overrides.hasOwnProperty('quickLtv') ? overrides.quickLtv! : 2128,
    quickValuation: overrides && overrides.hasOwnProperty('quickValuation') ? overrides.quickValuation! : 1734,
    rejectionReason: overrides && overrides.hasOwnProperty('rejectionReason') ? overrides.rejectionReason! : 'nostrum',
    securityType: overrides && overrides.hasOwnProperty('securityType') ? overrides.securityType! : SecurityType.Cash,
    subtype: overrides && overrides.hasOwnProperty('subtype') ? overrides.subtype! : AssetSubtype.Amethyst,
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : AssetType.Cash,
    updatedAt: overrides && overrides.hasOwnProperty('updatedAt') ? overrides.updatedAt! : new Date().toISOString(),
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
    valuation: overrides && overrides.hasOwnProperty('valuation') ? overrides.valuation! : 5749,
  };
};

export const MockItemCustodyRecord = (
  overrides?: Partial<ItemCustodyRecord>,
  _relationshipsToOmit: Set<string> = new Set(),
): ItemCustodyRecord => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('ItemCustodyRecord');
  return {
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : true,
    actor:
      overrides && overrides.hasOwnProperty('actor')
        ? overrides.actor!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '5b75add6-586f-4fd3-9f74-1264804b010c',
    inCustodyOf: overrides && overrides.hasOwnProperty('inCustodyOf') ? overrides.inCustodyOf! : CustodyType.Gia,
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    item:
      overrides && overrides.hasOwnProperty('item')
        ? overrides.item!
        : relationshipsToOmit.has('Item')
          ? ({} as Item)
          : MockItem({}, relationshipsToOmit),
  };
};

export const MockItemEvent = (
  overrides?: Partial<ItemEvent>,
  _relationshipsToOmit: Set<string> = new Set(),
): ItemEvent => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('ItemEvent');
  return {
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : true,
    actor:
      overrides && overrides.hasOwnProperty('actor')
        ? overrides.actor!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
    creditValue: overrides && overrides.hasOwnProperty('creditValue') ? overrides.creditValue! : 4043,
    creditValueOverride:
      overrides && overrides.hasOwnProperty('creditValueOverride') ? overrides.creditValueOverride! : 3318,
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'quasi',
    details: overrides && overrides.hasOwnProperty('details') ? overrides.details! : 'repellat',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'bff363f5-35a3-4aa8-acc9-f7afc63be1d6',
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    item:
      overrides && overrides.hasOwnProperty('item')
        ? overrides.item!
        : relationshipsToOmit.has('Item')
          ? ({} as Item)
          : MockItem({}, relationshipsToOmit),
    itemEventType:
      overrides && overrides.hasOwnProperty('itemEventType') ? overrides.itemEventType! : ItemEventType.CreditOverride,
    updatedAt: overrides && overrides.hasOwnProperty('updatedAt') ? overrides.updatedAt! : new Date().toISOString(),
    valuation: overrides && overrides.hasOwnProperty('valuation') ? overrides.valuation! : 6033,
  };
};

export const MockListCardsResult = (
  overrides?: Partial<ListCardsResult>,
  _relationshipsToOmit: Set<string> = new Set(),
): ListCardsResult => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('ListCardsResult');
  return {
    cards:
      overrides && overrides.hasOwnProperty('cards')
        ? overrides.cards!
        : [relationshipsToOmit.has('Card') ? ({} as Card) : MockCard({}, relationshipsToOmit)],
    pagination:
      overrides && overrides.hasOwnProperty('pagination')
        ? overrides.pagination!
        : relationshipsToOmit.has('PaginationDetails')
          ? ({} as PaginationDetails)
          : MockPaginationDetails({}, relationshipsToOmit),
  };
};

export const MockListCreditApplicationsResult = (
  overrides?: Partial<ListCreditApplicationsResult>,
  _relationshipsToOmit: Set<string> = new Set(),
): ListCreditApplicationsResult => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('ListCreditApplicationsResult');
  return {
    creditApplications:
      overrides && overrides.hasOwnProperty('creditApplications')
        ? overrides.creditApplications!
        : [
            relationshipsToOmit.has('CreditApplication')
              ? ({} as CreditApplication)
              : MockCreditApplication({}, relationshipsToOmit),
          ],
    pagination:
      overrides && overrides.hasOwnProperty('pagination')
        ? overrides.pagination!
        : relationshipsToOmit.has('PaginationDetails')
          ? ({} as PaginationDetails)
          : MockPaginationDetails({}, relationshipsToOmit),
  };
};

export const MockListStatementsResult = (
  overrides?: Partial<ListStatementsResult>,
  _relationshipsToOmit: Set<string> = new Set(),
): ListStatementsResult => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('ListStatementsResult');
  return {
    pagination:
      overrides && overrides.hasOwnProperty('pagination')
        ? overrides.pagination!
        : relationshipsToOmit.has('PaginationDetails')
          ? ({} as PaginationDetails)
          : MockPaginationDetails({}, relationshipsToOmit),
    statements:
      overrides && overrides.hasOwnProperty('statements')
        ? overrides.statements!
        : [relationshipsToOmit.has('Statement') ? ({} as Statement) : MockStatement({}, relationshipsToOmit)],
  };
};

export const MockListUsersResult = (
  overrides?: Partial<ListUsersResult>,
  _relationshipsToOmit: Set<string> = new Set(),
): ListUsersResult => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('ListUsersResult');
  return {
    pagination:
      overrides && overrides.hasOwnProperty('pagination')
        ? overrides.pagination!
        : relationshipsToOmit.has('PaginationDetails')
          ? ({} as PaginationDetails)
          : MockPaginationDetails({}, relationshipsToOmit),
    users:
      overrides && overrides.hasOwnProperty('users')
        ? overrides.users!
        : [relationshipsToOmit.has('User') ? ({} as User) : MockUser({}, relationshipsToOmit)],
  };
};

export const MockPaginationDetails = (
  overrides?: Partial<PaginationDetails>,
  _relationshipsToOmit: Set<string> = new Set(),
): PaginationDetails => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('PaginationDetails');
  return {
    hasNextPage: overrides && overrides.hasOwnProperty('hasNextPage') ? overrides.hasNextPage! : false,
    pageNumber: overrides && overrides.hasOwnProperty('pageNumber') ? overrides.pageNumber! : 5867,
    resultsPerPage: overrides && overrides.hasOwnProperty('resultsPerPage') ? overrides.resultsPerPage! : 1239,
    totalPages: overrides && overrides.hasOwnProperty('totalPages') ? overrides.totalPages! : 3203,
    totalRecords: overrides && overrides.hasOwnProperty('totalRecords') ? overrides.totalRecords! : 5316,
  };
};

export const MockPaymentCheck = (
  overrides?: Partial<PaymentCheck>,
  _relationshipsToOmit: Set<string> = new Set(),
): PaymentCheck => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('PaymentCheck');
  return {
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : true,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'aef17ac2-84fb-4cd2-ba99-e7a23696f4d9',
    paymentVerifications:
      overrides && overrides.hasOwnProperty('paymentVerifications')
        ? overrides.paymentVerifications!
        : [
            relationshipsToOmit.has('PaymentVerification')
              ? ({} as PaymentVerification)
              : MockPaymentVerification({}, relationshipsToOmit),
          ],
    reason: overrides && overrides.hasOwnProperty('reason') ? overrides.reason! : 'bellum',
    reviewedBy:
      overrides && overrides.hasOwnProperty('reviewedBy')
        ? overrides.reviewedBy!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : PaymentCheckStatus.Approved,
  };
};

export const MockPaymentDetails = (
  overrides?: Partial<PaymentDetails>,
  _relationshipsToOmit: Set<string> = new Set(),
): PaymentDetails => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('PaymentDetails');
  return {
    paidAmountExcludingFee:
      overrides && overrides.hasOwnProperty('paidAmountExcludingFee') ? overrides.paidAmountExcludingFee! : 6729,
    paidAmountIncludingFee:
      overrides && overrides.hasOwnProperty('paidAmountIncludingFee') ? overrides.paidAmountIncludingFee! : 2124,
    remainingAmountToPay:
      overrides && overrides.hasOwnProperty('remainingAmountToPay') ? overrides.remainingAmountToPay! : 1105,
    totalToPayExcludingFee:
      overrides && overrides.hasOwnProperty('totalToPayExcludingFee') ? overrides.totalToPayExcludingFee! : 1711,
    totalToPayIncludingFee:
      overrides && overrides.hasOwnProperty('totalToPayIncludingFee') ? overrides.totalToPayIncludingFee! : 5194,
  };
};

export const MockPaymentVerification = (
  overrides?: Partial<PaymentVerification>,
  _relationshipsToOmit: Set<string> = new Set(),
): PaymentVerification => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('PaymentVerification');
  return {
    amountEntered: overrides && overrides.hasOwnProperty('amountEntered') ? overrides.amountEntered! : 1289,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '56db9ffe-80fa-4658-853a-dd0918ba6ede',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  };
};

export const MockPhotoLink = (
  overrides?: Partial<PhotoLink>,
  _relationshipsToOmit: Set<string> = new Set(),
): PhotoLink => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('PhotoLink');
  return {
    blob: overrides && overrides.hasOwnProperty('blob') ? overrides.blob! : 'consequatur',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f594937f-0f0c-452a-8b9e-89920d72aacc',
    item:
      overrides && overrides.hasOwnProperty('item')
        ? overrides.item!
        : relationshipsToOmit.has('Item')
          ? ({} as Item)
          : MockItem({}, relationshipsToOmit),
  };
};

export const MockRootQueryType = (
  overrides?: Partial<RootQueryType>,
  _relationshipsToOmit: Set<string> = new Set(),
): RootQueryType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('RootQueryType');
  return {
    card:
      overrides && overrides.hasOwnProperty('card')
        ? overrides.card!
        : relationshipsToOmit.has('Card')
          ? ({} as Card)
          : MockCard({}, relationshipsToOmit),
    creditApplication:
      overrides && overrides.hasOwnProperty('creditApplication')
        ? overrides.creditApplication!
        : relationshipsToOmit.has('CreditApplication')
          ? ({} as CreditApplication)
          : MockCreditApplication({}, relationshipsToOmit),
    currentUser:
      overrides && overrides.hasOwnProperty('currentUser')
        ? overrides.currentUser!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
    listCards:
      overrides && overrides.hasOwnProperty('listCards')
        ? overrides.listCards!
        : relationshipsToOmit.has('ListCardsResult')
          ? ({} as ListCardsResult)
          : MockListCardsResult({}, relationshipsToOmit),
    listCreditApplications:
      overrides && overrides.hasOwnProperty('listCreditApplications')
        ? overrides.listCreditApplications!
        : relationshipsToOmit.has('ListCreditApplicationsResult')
          ? ({} as ListCreditApplicationsResult)
          : MockListCreditApplicationsResult({}, relationshipsToOmit),
    listStatements:
      overrides && overrides.hasOwnProperty('listStatements')
        ? overrides.listStatements!
        : relationshipsToOmit.has('ListStatementsResult')
          ? ({} as ListStatementsResult)
          : MockListStatementsResult({}, relationshipsToOmit),
    listUsers:
      overrides && overrides.hasOwnProperty('listUsers')
        ? overrides.listUsers!
        : relationshipsToOmit.has('ListUsersResult')
          ? ({} as ListUsersResult)
          : MockListUsersResult({}, relationshipsToOmit),
    presignedPhotoLinks:
      overrides && overrides.hasOwnProperty('presignedPhotoLinks') ? overrides.presignedPhotoLinks! : ['abundans'],
    statement:
      overrides && overrides.hasOwnProperty('statement')
        ? overrides.statement!
        : relationshipsToOmit.has('Statement')
          ? ({} as Statement)
          : MockStatement({}, relationshipsToOmit),
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
  };
};

export const MockScheduledPayment = (
  overrides?: Partial<ScheduledPayment>,
  _relationshipsToOmit: Set<string> = new Set(),
): ScheduledPayment => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('ScheduledPayment');
  return {
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : false,
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 6459,
    creditApplication:
      overrides && overrides.hasOwnProperty('creditApplication')
        ? overrides.creditApplication!
        : relationshipsToOmit.has('CreditApplication')
          ? ({} as CreditApplication)
          : MockCreditApplication({}, relationshipsToOmit),
    date: overrides && overrides.hasOwnProperty('date') ? overrides.date! : new Date().toISOString(),
    deletedAt: overrides && overrides.hasOwnProperty('deletedAt') ? overrides.deletedAt! : new Date().toISOString(),
    groupId: overrides && overrides.hasOwnProperty('groupId') ? overrides.groupId! : 'suadeo',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'a1db242b-a1bf-4b01-be2e-c6ada7a0fcce',
  };
};

export const MockStatement = (
  overrides?: Partial<Statement>,
  _relationshipsToOmit: Set<string> = new Set(),
): Statement => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('Statement');
  return {
    creditProductId: overrides && overrides.hasOwnProperty('creditProductId') ? overrides.creditProductId! : 'virtus',
    dailyFile:
      overrides && overrides.hasOwnProperty('dailyFile')
        ? overrides.dailyFile!
        : relationshipsToOmit.has('DailyFile')
          ? ({} as DailyFile)
          : MockDailyFile({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '4c909d4b-171b-461c-a7c1-c8c05343c974',
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    statementDate: overrides && overrides.hasOwnProperty('statementDate') ? overrides.statementDate! : 'sponte',
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : StatementStatus.Accepted,
    updatedAt: overrides && overrides.hasOwnProperty('updatedAt') ? overrides.updatedAt! : new Date().toISOString(),
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : MockUser({}, relationshipsToOmit),
  };
};

export const MockUser = (overrides?: Partial<User>, _relationshipsToOmit: Set<string> = new Set()): User => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit);
  relationshipsToOmit.add('User');
  return {
    address:
      overrides && overrides.hasOwnProperty('address')
        ? overrides.address!
        : relationshipsToOmit.has('Address')
          ? ({} as Address)
          : MockAddress({}, relationshipsToOmit),
    addresses:
      overrides && overrides.hasOwnProperty('addresses')
        ? overrides.addresses!
        : [relationshipsToOmit.has('Address') ? ({} as Address) : MockAddress({}, relationshipsToOmit)],
    adminActionRecords:
      overrides && overrides.hasOwnProperty('adminActionRecords')
        ? overrides.adminActionRecords!
        : [
            relationshipsToOmit.has('AdminActionRecord')
              ? ({} as AdminActionRecord)
              : MockAdminActionRecord({}, relationshipsToOmit),
          ],
    annualIncome: overrides && overrides.hasOwnProperty('annualIncome') ? overrides.annualIncome! : 6800,
    billingAddress:
      overrides && overrides.hasOwnProperty('billingAddress')
        ? overrides.billingAddress!
        : relationshipsToOmit.has('Address')
          ? ({} as Address)
          : MockAddress({}, relationshipsToOmit),
    cards:
      overrides && overrides.hasOwnProperty('cards')
        ? overrides.cards!
        : [relationshipsToOmit.has('Card') ? ({} as Card) : MockCard({}, relationshipsToOmit)],
    creditApplications:
      overrides && overrides.hasOwnProperty('creditApplications')
        ? overrides.creditApplications!
        : [
            relationshipsToOmit.has('CreditApplication')
              ? ({} as CreditApplication)
              : MockCreditApplication({}, relationshipsToOmit),
          ],
    displayName: overrides && overrides.hasOwnProperty('displayName') ? overrides.displayName! : 'depulso',
    dob: overrides && overrides.hasOwnProperty('dob') ? overrides.dob! : 'vita',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'natus',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'trans',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'b5756f00-51a6-422a-81a7-dc13ee6a6375',
    insertedAt: overrides && overrides.hasOwnProperty('insertedAt') ? overrides.insertedAt! : new Date().toISOString(),
    isCardholder: overrides && overrides.hasOwnProperty('isCardholder') ? overrides.isCardholder! : false,
    isFriendsAndFam: overrides && overrides.hasOwnProperty('isFriendsAndFam') ? overrides.isFriendsAndFam! : true,
    lastName: overrides && overrides.hasOwnProperty('lastName') ? overrides.lastName! : 'avaritia',
    middleName: overrides && overrides.hasOwnProperty('middleName') ? overrides.middleName! : 'calcar',
    paymentAddress:
      overrides && overrides.hasOwnProperty('paymentAddress')
        ? overrides.paymentAddress!
        : relationshipsToOmit.has('Address')
          ? ({} as Address)
          : MockAddress({}, relationshipsToOmit),
    performedAdminActionRecords:
      overrides && overrides.hasOwnProperty('performedAdminActionRecords')
        ? overrides.performedAdminActionRecords!
        : [
            relationshipsToOmit.has('AdminActionRecord')
              ? ({} as AdminActionRecord)
              : MockAdminActionRecord({}, relationshipsToOmit),
          ],
    phone: overrides && overrides.hasOwnProperty('phone') ? overrides.phone! : 'pax',
    qrCode: overrides && overrides.hasOwnProperty('qrCode') ? overrides.qrCode! : 'occaecati',
    quality: overrides && overrides.hasOwnProperty('quality') ? overrides.quality! : 7238,
    risk: overrides && overrides.hasOwnProperty('risk') ? overrides.risk! : 8778,
    shippingAddress:
      overrides && overrides.hasOwnProperty('shippingAddress')
        ? overrides.shippingAddress!
        : relationshipsToOmit.has('Address')
          ? ({} as Address)
          : MockAddress({}, relationshipsToOmit),
    updatedAt: overrides && overrides.hasOwnProperty('updatedAt') ? overrides.updatedAt! : new Date().toISOString(),
  };
};
