import { useListQuery } from '@pesto/hooks';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useNavigate } from 'react-router-dom';

import { useListCreditApplicationsQuery, type CreditApplication } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

import { useCreditApplicationsConfig } from './hooks/useCreditApplicationsConfig';

export const CreditApplications = () => {
  const navigate = useNavigate();
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListCreditApplicationsQuery,
  });
  const { getListCreditApplicationsColumns, listCreditApplicationsFilterFields, initialTableState } =
    useCreditApplicationsConfig();
  const handleRowClicked = (row: CreditApplication) => {
    navigate(`${routes.creditApplications}/${row.id}`);
  };
  const amountOfRows = data?.listCreditApplications?.pagination.totalRecords;

  const rows = data?.listCreditApplications?.creditApplications ?? [];
  const columns = getListCreditApplicationsColumns(handleRowClicked);

  return (
    <SmartTable
      title="Credit Applications"
      amountOfRows={amountOfRows}
      rows={rows}
      columns={columns}
      filterFields={listCreditApplicationsFilterFields}
      loading={loadingOrReFetching}
      pageCount={data?.listCreditApplications?.pagination?.totalPages}
      initialState={initialTableState}
      enableDateRangeFilter
    />
  );
};
