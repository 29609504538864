import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Typography } from '@pesto/ui';
import { TextField } from '@pesto/ui/components/Forms/TextField';
import { formatDate } from '@pesto/utils';
import { MONEY_MULTIPLIER } from '@pesto/utils/constants/pestoConstants';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useGetUserByIdQuery } from '../../../__generated__/graphql/api';

import { UserDetailsCard } from './UserDetailsCard';

interface UserPersonalInfoProps {
  userId: string | undefined | null;
}

interface UserFormData {
  firstName: string;
  lastName: string;
  middleName?: string;
  phone: string;
  isFriendsAndFam?: boolean;
  dob: string;
  annualIncome: number;
}

const schema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  middleName: z.string().optional(),
  phone: z.string().min(1, 'Phone is required'),
  isFriendsAndFam: z.boolean().optional(),
  dob: z
    .string()
    .min(1, 'Date of birth is required')
    .regex(/^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])/, 'DOB must be in the format YYYY-MM-DD'),
  annualIncome: z.number().min(1, 'Annual income is required'),
});

export const UserPersonalInfo = (props: UserPersonalInfoProps) => {
  const { userId } = props;
  const { data } = useGetUserByIdQuery({
    variables: { userId: userId ?? '' },
  });
  const user = data?.user;

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserFormData>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      middleName: user?.middleName || '',
      phone: user?.phone || '',
      dob: user?.dob || '',
      annualIncome: user?.annualIncome ? user.annualIncome / MONEY_MULTIPLIER : 0,
      isFriendsAndFam: user?.isFriendsAndFam || false,
    },
  });

  const [editMode, setEditMode] = useState(false);
  const formData = watch();

  const handleSave = () => {
    return null;
  };
  const handleCancel = () => {
    reset({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      middleName: user?.middleName || '',
      phone: user?.phone || '',
      dob: user?.dob || '',
      annualIncome: user?.annualIncome ? user.annualIncome / MONEY_MULTIPLIER : 0,
      isFriendsAndFam: user?.isFriendsAndFam || false,
    });
    setEditMode(false);
  };

  return (
    <UserDetailsCard className={'w-full'}>
      <form className={'flex flex-col gap-4'} onSubmit={handleSubmit(handleSave)}>
        <div className={'grid gap-4 sm:grid-cols-2'}>
          <TextField onChange={() => {}} value={user?.id || ''} label={'Id'} readOnly={true} />
          <TextField
            {...register('firstName')}
            label={'First Name'}
            value={formData.firstName || ''}
            readOnly={!editMode}
          />
          <TextField
            {...register('middleName')}
            label={'Middle Name'}
            value={formData.middleName || ''}
            readOnly={!editMode}
          />

          <TextField
            {...register('lastName')}
            label={'Last Name'}
            value={formData.lastName || ''}
            readOnly={!editMode}
          />
          <TextField {...register('phone')} label={'Phone'} value={formData.phone || ''} readOnly={!editMode} />
          <TextField onChange={() => {}} value={user?.email || ''} label={'Email'} readOnly={true} />
          <TextField {...register('dob')} value={formData?.dob || ''} label={'DOB'} readOnly={!editMode} />
          <TextField
            {...register('annualIncome')}
            value={formData.annualIncome}
            label={'Annual Income'}
            readOnly={!editMode}
          />
          <TextField onChange={() => {}} value={formatDate(user?.insertedAt) || ''} label={'Created'} readOnly={true} />
        </div>
        <div className={'flex items-center gap-4'}>
          <>
            {editMode ? (
              <Button onClick={handleSubmit(handleSave)} disabled={false}>
                Save
              </Button>
            ) : (
              <Button onClick={() => setEditMode(!editMode)}>Edit User</Button>
            )}
            {editMode && (
              <Button variant={'outline'} onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </>
        </div>
        {errors &&
          Object.keys(errors).map((key: string) => {
            return (
              <Typography variant={'body'} className={'text-danger'} key={key}>
                {errors[key as keyof UserFormData]?.message}
              </Typography>
            );
          })}
      </form>
    </UserDetailsCard>
  );
};
