import { LoadingSpinner } from '@pesto/ui';
import { DropdownInput } from '@pesto/ui/components/DropdownInput';
import { Label } from '@pesto/ui/components/Label';
import { TextArea } from '@pesto/ui/components/TextArea';
import { formatApplicationValues, humanize } from '@pesto/utils';
import { useCallback, useMemo, useState } from 'react';

import type { AssetType } from '../../../__generated__/graphql/api';
import {
  AdminActionRecordType,
  AssetSubtype,
  DocCheckStatus,
  ItemEventType,
  PaymentCheckStatus,
  type CreditApplication,
} from '../../../__generated__/graphql/api';
import { addBasicItemsOptions } from '../CreditApplicationDetail';

interface UseApplicationModalProps {
  application?: CreditApplication | null;
}

export enum ApplicationActions {
  APPROVE = 'APPROVE',
  SEND = 'SEND',

  CLOSE_APPLICATION = 'CLOSE_APPLICATION',

  CREATE_LOG = 'CREATE_LOG',
  CREATING_LABELS = 'CREATING_LABELS',
  CREATE_BASIC_ITEM = 'CREATE_BASIC_ITEM',
  INBOUND_SHIPPING = 'INBOUND_SHIPPING',

  FORCE_DOC_CHECK = 'FORCE_DOC_CHECK',
  FORCE_PAYMENT_CHECK = 'FORCE_PAYMENT_CHECK',
  REVIEW_DOC_CHECK = 'REVIEW_DOC_CHECK',
  REVIEW_PAYMENT_CHECK = 'REVIEW_PAYMENT_CHECK',
  TOGGLE_DOC_VERIFICATION = 'TOGGLE_DOC_VERIFICATION',
  TOGGLE_PAYMENT_VERIFICATION = 'TOGGLE_PAYMENT_VERIFICATION',
  RUN_SOFT_CREDIT_CHECK = 'RUN_SOFT_CREDIT_CHECK',
  RUN_HARD_CREDIT_CHECK_MAYBE_CARD_ORDER = 'RUN_HARD_CREDIT_CHECK_MAYBE_CARD_ORDER',
}

export const useApplicationModal = ({ application }: UseApplicationModalProps) => {
  const [newBasicItem, setNewBasicItem] = useState<AssetType>();

  const [actionRecord, setActionRecord] = useState<{ action?: AdminActionRecordType; description?: string }>({
    action: undefined,
    description: undefined,
  });
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState<ApplicationActions | null>(null);
  const [, setApproveDenyReason] = useState('');
  const [docCheckStatus, setDocCheckStatus] = useState<DocCheckStatus>(DocCheckStatus.NotDone);
  const [paymentCheckStatus, setPaymentCheckStatus] = useState<PaymentCheckStatus>(PaymentCheckStatus.NotDone);

  const getModalTitle = useCallback(() => {
    if (action === ApplicationActions.CREATING_LABELS) {
      return (
        <div>
          <strong>One moment please. Creating/Retrieving Label.</strong>
        </div>
      );
    }
    return (
      <div>
        Please confirm you want to <strong>{humanize(action || '')}</strong> this{' '}
        {`${action === ApplicationActions.SEND ? ' Offer' : ' Application'}`}
      </div>
    );
  }, [action]);

  const modalReasonTextArea = (
    <>
      <Label>Reason</Label>
      <TextArea defaultValue={''} onChange={value => setApproveDenyReason(value.target.value)} />
    </>
  );

  const sendOfferContent = (
    <div>
      {application?.offerAmount && Number(application?.offerAmount.replace(/[^0-9.]+/g, '')) < 300 && (
        <div className={'align-items-center bg-destructive my-4 items-center p-2 text-center text-white'}>
          Attention: This offer is below policy
        </div>
      )}
      <div className={'items-center'}>
        <strong>Offer Summary</strong>
      </div>
      <div className={'bg-basicSoft h-1 max-w-full'} />
      <div>
        {application?.items
          ?.filter((i: any) => i?.active === true)
          ?.map((item: any) => {
            const type = item?.subtype === AssetSubtype.Other ? item.otherAssetType : item?.subtype;
            const finalAppraisal = item?.itemEvents?.filter(
              (itemEvent: any) => itemEvent?.itemEventType === ItemEventType.FinalAppraisal,
            )?.[0];
            return (
              <div key={item?.id}>
                <strong>{humanize(type ?? '')}</strong>:{' '}
                {finalAppraisal?.creditValue && finalAppraisal?.creditValue > 0
                  ? formatApplicationValues('valuation', finalAppraisal?.creditValue)
                  : '$0.00'}
              </div>
            );
          })}
      </div>
      <div className={'bg-basicSoft h-1 max-w-full'} />
      <div>
        <strong>Total Amount:</strong> {application?.offerAmount}
      </div>
    </div>
  );

  const actionRecordOptions = useMemo(() => {
    return Object.values(AdminActionRecordType)?.map(dd => {
      return { value: dd, label: humanize(dd) };
    });
  }, []);

  const docCheckStatusOptions = useMemo(() => {
    return Object.values(DocCheckStatus)?.map(dd => {
      return { value: dd, label: humanize(dd) };
    });
  }, []);

  const paymentCheckStatusOptions = useMemo(() => {
    return Object.values(PaymentCheckStatus)?.map(dd => {
      return { value: dd, label: humanize(dd) };
    });
  }, []);

  const modalContent = () => {
    const actionComponents = {
      [ApplicationActions.CREATING_LABELS]: <LoadingSpinner size={100} />,
      [ApplicationActions.SEND]: sendOfferContent,
      [ApplicationActions.CREATE_BASIC_ITEM]: (
        <>
          <Label>Select Item</Label>
          <DropdownInput
            options={addBasicItemsOptions}
            value={newBasicItem}
            onChange={v => setNewBasicItem(v as AssetType)}
          />
        </>
      ),
      [ApplicationActions.CREATE_LOG]: (
        <div>
          <Label>Record Type</Label>
          <DropdownInput
            options={actionRecordOptions}
            value={actionRecord.action}
            onChange={value =>
              setActionRecord({
                ...actionRecord,
                action: value as AdminActionRecordType,
              })
            }
          />
          <Label>Note</Label>
          <TextArea
            defaultValue={''}
            onChange={value => setActionRecord({ ...actionRecord, description: value.target.value })}
          />
        </div>
      ),
      [ApplicationActions.APPROVE]: modalReasonTextArea,
      [ApplicationActions.REVIEW_PAYMENT_CHECK]: (
        <>
          <Label>Select Payment Check Status</Label>
          <DropdownInput
            options={paymentCheckStatusOptions}
            value={paymentCheckStatus}
            onChange={value => setPaymentCheckStatus(value as PaymentCheckStatus)}
          />
          {modalReasonTextArea}
        </>
      ),
      [ApplicationActions.REVIEW_DOC_CHECK]: (
        <>
          <Label>Select Doc Check Status</Label>
          <DropdownInput
            options={docCheckStatusOptions}
            value={docCheckStatus}
            onChange={value => setDocCheckStatus(value as DocCheckStatus)}
          />
          {modalReasonTextArea}
        </>
      ),
      [ApplicationActions.INBOUND_SHIPPING]: modalReasonTextArea,
    };

    return actionComponents[action as keyof typeof actionComponents];
  };

  return {
    showModal,
    setShowModal,
    action,
    setAction,
    getModalTitle,
    modalContent,
    docCheckStatus,
    setDocCheckStatus,
    paymentCheckStatus,
    setPaymentCheckStatus,
  };
};
