import { Button, Typography } from '@pesto/ui';
import { CornerLeftUpIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { routes } from '../constants/routes';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <main className="bg-background grid h-full place-items-center">
      <div className="text-center">
        <p className="text-6xl font-semibold">404</p>
        <Typography variant={'heroSmall'} className="mt-6">
          Page not found
        </Typography>

        <Typography variant={'body'} className="mt-6">
          Sorry, we couldn’t find the page you’re looking for.
        </Typography>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button
            onClick={e => {
              e.preventDefault();
              navigate(routes.dashboard);
            }}
          >
            <CornerLeftUpIcon className="mr-2 h-4 w-4" />
            Go back home
          </Button>
        </div>
      </div>
    </main>
  );
};
