import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Card, LoadingSpinner, PhoneInput, Typography } from '@pesto/ui';
import { SixDigitOtp } from '@pesto/ui/components/SixDigitOtp';
import { ThemeToggle } from '@pesto/ui/components/ThemeToggle';
import type { FC } from 'react';
import { useState } from 'react';

export type LoginScreenProps = {
  isAuthenticated: () => boolean;
  sendSmsCode: (phoneNumber: string) => Promise<void>;
  verifySmsCode: (phoneNumber: string, code: string) => Promise<void>;
  codeLoading: boolean;
  navigate: (path: string) => void;

  onCodeSent?: () => void;
  onVerified?: () => void;
  onError?: (error: Error) => void;
  title?: string;
  logo?: string;
  playerSrc?: string;
  redirectPath?: string;
  phoneInputPlaceholder?: string;
  sendCodeButtonText?: string;
  verifyCodeButtonText?: string;
};

export const LoginScreen: FC<LoginScreenProps> = ({
  isAuthenticated,
  sendSmsCode,
  verifySmsCode,
  codeLoading,
  navigate,
  onCodeSent,
  onVerified,
  onError,
  title,
  logo,
  playerSrc,
  redirectPath = '/dashboard',
  phoneInputPlaceholder = 'Enter phone number',
  sendCodeButtonText = 'Send Code',
  verifyCodeButtonText = 'Verify Code',
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePhoneSubmit = () => {
    sendSmsCode(phoneNumber)
      .then(() => {
        setIsCodeSent(true);
        onCodeSent?.();
      })
      .catch((err: Error) => {
        console.error('Error sending passcode:', err);
        onError?.(err);
      });
  };

  const handleVerificationCodeSubmit = () => {
    setIsLoading(true);
    verifySmsCode(phoneNumber, verificationCode)
      .then(() => {
        onVerified?.();
        navigate(redirectPath);
      })
      .catch((err: Error) => {
        setIsLoading(false);
        console.error('Error parsing hash:', err);
        onError?.(err);
      });
  };

  if (isAuthenticated()) {
    navigate(redirectPath);
    return null;
  }

  return (
    <div className="from-basic to-basicSoft relative flex min-h-screen flex-col items-center justify-center bg-gradient-to-t px-4 py-12 sm:px-6 lg:px-8">
      <ThemeToggle className="absolute right-4 top-4" />
      <Card className={'z-0 p-6'}>
        <div className="flex w-full max-w-md flex-col items-center space-y-8">
          <div className={'flex flex-col items-center gap-2'}>
            {logo && <img className="mx-auto h-12 w-auto" src={logo} alt="Company Logo" />}
            <Player autoplay speed={1} loop src={playerSrc || ''} className={'h-auto max-w-[250px]'} />
            <Typography variant={'headerLarge'}>{title}</Typography>
            <Typography variant={'headerMedium'}>
              {isCodeSent ? 'Enter Verification Code' : 'Sign in to your account'}
            </Typography>
            {isCodeSent && <span className="text-sm text-gray-500">Sent to {phoneNumber}</span>}
            {isCodeSent && (
              <Button variant={'link'} className={''} onClick={() => setIsCodeSent(false)}>
                Reset form
              </Button>
            )}
          </div>
          {isLoading || codeLoading ? (
            <LoadingSpinner />
          ) : !isCodeSent ? (
            <form
              className={'flex flex-col gap-4'}
              onSubmit={e => {
                e.preventDefault();
                handlePhoneSubmit();
              }}
            >
              <PhoneInput
                placeholder={phoneInputPlaceholder}
                value={phoneNumber}
                onChange={(value?: string) => setPhoneNumber(value || '')}
              />
              <Button type={'submit'}>{sendCodeButtonText}</Button>
            </form>
          ) : (
            <form
              className={'flex flex-col gap-4'}
              onSubmit={e => {
                e.preventDefault();
                handleVerificationCodeSubmit();
              }}
            >
              <SixDigitOtp value={verificationCode} onChange={setVerificationCode} />
              <Button type={'submit'}>{verifyCodeButtonText}</Button>
            </form>
          )}
        </div>
      </Card>
    </div>
  );
};
