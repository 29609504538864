import { Button, Card, Typography } from '@pesto/ui';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@pesto/ui/components/Accordion';
import { DataList } from '@pesto/ui/components/DataList';
import { humanize } from '@pesto/utils';

import type { DocCheck } from '../../../__generated__/graphql/api';

interface CheckContentProps {
  title: string;
  checks?: any;
  buttonTitle?: string;
  onButtonClick?: (docCheckId: string) => void;
}

export const DocCheckContent = (props: CheckContentProps) => {
  const { title, checks, buttonTitle, onButtonClick } = props;
  if (!checks) return null;
  return (
    <div className={'space-y-4'}>
      <Typography variant="headerMedium">{`${title}s list`}</Typography>
      <div className="grid grid-cols-2 gap-6">
        {checks.map((docCheck: DocCheck, index: number) => (
          <Accordion type="single" collapsible key={index}>
            <Card>
              <AccordionItem value={`item-${index}`} className="px-4 py-4">
                <AccordionTrigger>
                  <Typography variant={'body'}>
                    {title} #{index}: {humanize(docCheck?.reason ?? '')} | {humanize(docCheck?.status ?? '')}
                  </Typography>
                </AccordionTrigger>
                {docCheck && (
                  <AccordionContent className="space-y-4">
                    <DataList root={docCheck} />
                    {onButtonClick && (
                      <Button
                        onClick={() => {
                          onButtonClick(docCheck?.id || '');
                        }}
                      >
                        {buttonTitle || 'Action'}
                      </Button>
                    )}
                  </AccordionContent>
                )}
              </AccordionItem>
            </Card>
          </Accordion>
        ))}
      </div>
    </div>
  );
};
