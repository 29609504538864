import { Button, Typography } from '@pesto/ui';
import { Modal } from '@pesto/ui/components/Modal';
import React from 'react';

import type { GetUserByIdQuery } from '../../../../__generated__/graphql/api';

export type QuickActionModalType = 'risk' | 'quality' | null;

interface QuickActionModalProps {
  user: GetUserByIdQuery['user'];
  isOpen: boolean;
  type: QuickActionModalType;
  onClose: () => void;
}

export const USER_QUALITY = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
} as const;

export const USER_RISK = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
} as const;

export const QuickActionModal = (props: QuickActionModalProps) => {
  const { isOpen, type, onClose } = props;

  const [numberState, setNumberState] = React.useState<number>(1);

  const modalText = {
    risk: {
      title: 'Update risk',
      content: 'Are you sure you want to update risk?',
      label: 'Risk',
      input: true,
      inputMap: USER_RISK,
    },
    quality: {
      title: 'Update quality',
      content: 'Are you sure you want to update quality?',
      label: 'Quality',
      input: true,
      inputMap: USER_QUALITY,
    },
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={modalText[type as keyof typeof modalText]?.title}>
      <div className={'flex flex-col gap-4'}>
        <div className={'flex flex-col items-center gap-4'}>
          <Typography variant={'body'}>{modalText[type as keyof typeof modalText]?.content}</Typography>
          {modalText[type as keyof typeof modalText]?.input && (
            <>
              <div className={'flex justify-center gap-4'}>
                {modalText[type as keyof typeof modalText]?.inputMap &&
                  Object.keys(modalText[type as keyof typeof modalText]?.inputMap).map(key => {
                    const inputMap = modalText[type as keyof typeof modalText]?.inputMap;
                    const isSelect = inputMap[key as keyof typeof inputMap] === numberState;
                    return (
                      <Button
                        key={key}
                        color={'secondary'}
                        onClick={() => setNumberState(inputMap[key as keyof typeof inputMap])}
                        variant={isSelect ? 'primary' : 'outline'}
                      >
                        {key}
                      </Button>
                    );
                  })}
              </div>
              <Button
                className={'w-full'}
                onClick={() => {
                  if (type === 'risk') {
                    return null;
                  }
                  if (type === 'quality') {
                    return null;
                  }
                  onClose();
                }}
              >
                Update {modalText[type as keyof typeof modalText]?.label}
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
