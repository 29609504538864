import { useAuth } from '@pesto/features';
import type { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProtectedRoute } from '../components/ProtectedRoute';
import { routes } from '../constants/routes';
import { PartnerApplicationLayout } from '../layouts/PartnerApplicationLayout';
import { CreditApplicationDetail } from '../pages/CreditApplicationDetail/CreditApplicationDetail';
import { CreditApplications } from '../pages/CreditApplications/CreditApplications';
import { Dashboard } from '../pages/Dashboard';
import { Login } from '../pages/Login';
import LoginCallback from '../pages/LoginCallback';
import { NotFound } from '../pages/NotFound';

export const AppRoutesProvider = ({ children }: { children: ReactNode }) => {
  return <PartnerApplicationLayout>{children}</PartnerApplicationLayout>;
};

export const RouterProvider = () => {
  const { isAuthenticated } = useAuth();
  return (
    <AppRoutesProvider>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route index element={<Login />} />
        <Route path={routes.loginCallback} element={<LoginCallback />} />
        {/* <Route element={<ProtectedRoute isAuthenticated={isAuthenticated()} />}> */}
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated()} />}>
          <Route path={routes.dashboard} element={<Dashboard />} />
          <Route path={routes.creditApplications} element={<CreditApplications />} />
          <Route path={`${routes.creditApplications}/:id`} element={<CreditApplicationDetail />} />
        </Route>
      </Routes>
    </AppRoutesProvider>
  );
};
