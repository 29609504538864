import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import { BooleanTag } from '@pesto/ui/features/SmartTable/components/BooleanTag';
import {
  generateSelectableFilterOptions,
  setColumnFilterAndSortKey,
} from '@pesto/ui/features/SmartTable/utils/helpers';
import { formatDate } from '@pesto/utils';
import type { ColumnDef, TableState } from '@tanstack/react-table';

import type { CreditApplication } from '../../../__generated__/graphql/api';
import { CreditApplicationStatus, PaymentPreference, SecurityType } from '../../../__generated__/graphql/api';

const columnIdNames = {
  creditAppId: setColumnFilterAndSortKey('idSearch', 'idSortDirection'),
  insertedAt: setColumnFilterAndSortKey('', 'insertedAtSortDirection'),
  status: setColumnFilterAndSortKey('statusSelections', 'statusSortDirection'),
  securityType: setColumnFilterAndSortKey('securityTypeSelections', 'securityTypeSortDirection'),
  paymentPreference: setColumnFilterAndSortKey('paymentPreferenceSelections', 'paymentPreferenceSortDirection'),
  userId: setColumnFilterAndSortKey('pestoUserIdSearch', 'pestoUserIdSortDirection'),
};

export const useCreditApplicationsConfig = () => {
  const listCreditApplicationsFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'Credit Application ID',
      value: columnIdNames.creditAppId,
      placeholder: 'Search by Credit Application ID',
    },
    {
      label: 'Status',
      value: columnIdNames.status,
      options: Object.entries(CreditApplicationStatus).map(([, value]) => ({
        label: value[0]?.toUpperCase() + value.slice(1),
        value: value,
        withCount: true,
      })),
    },
    {
      label: 'Security Type',
      value: columnIdNames.securityType,
      options: Object.entries(SecurityType).map(([, value]) => ({
        label: value[0]?.toUpperCase() + value.slice(1),
        value: value,
        withCount: true,
      })),
    },
    {
      label: 'Payment Preference',
      value: columnIdNames.paymentPreference,
      options: Object.entries(PaymentPreference).map(([, value]) => ({
        label: value[0]?.toUpperCase() + value.slice(1),
        value: value,
        withCount: true,
      })),
    },
    {
      label: 'Custom Filters',
      value: 'customFilters',
      options: generateSelectableFilterOptions({
        'Display first Credit Applications only': 'firstOf',
      }),
      isCustomCheckboxFilter: true,
    },
  ];

  const getListCreditApplicationsColumns = (
    handleRowClicked: (data: CreditApplication) => void,
  ): ColumnDef<CreditApplication>[] => [
    {
      id: columnIdNames.creditAppId,
      header: 'Credit Application ID',
      accessorFn: row => row.id,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.insertedAt,
      accessorFn: row => row.insertedAt,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created" />,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: columnIdNames.status,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
      accessorFn: row => row.status,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.securityType,
      header: 'Security Type',
      accessorFn: row => row.securityType,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.paymentPreference,
      header: 'Payment Preference',
      accessorFn: row => row.paymentPreference,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.userId,
      header: 'User ID',
      accessorFn: row => row.pestoUser?.id,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'firstName',
      header: 'First Name',
      accessorFn: row => row.pestoUser?.firstName,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'lastName',
      header: 'Last Name',
      accessorFn: row => row.pestoUser?.lastName,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'billingCity',
      header: 'Billing City',
      accessorFn: row => row.pestoUser?.billingAddress?.city,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'billingState',
      header: 'Billing State',
      accessorFn: row => row.pestoUser?.billingAddress?.state,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'billingEmail',
      header: 'Billing Email',
      accessorFn: row => row.pestoUser?.email,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'friendsAndFamily',
      header: 'Friends and Family',
      accessorFn: row => row.pestoUser?.isFriendsAndFam,
      cell: row => BooleanTag({ value: row.cell.getValue() as boolean }),
    },
    {
      id: 'view',
      header: 'Action',
      enablePinning: true,
      cell: row => (
        <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
          View
        </Button>
      ),
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    sorting: [{ id: 'insertedAtSortDirection', desc: true }],
    columnVisibility: {
      userId: false,
      friendsAndFamily: false,
      hasUserShipment: false,
      needsOutboundShippingKitCreated: false,
      needsOutboundShippingKitSent: false,
      outboundShippingKitSent: false,
    },
  };

  return { listCreditApplicationsFilterFields, getListCreditApplicationsColumns, initialTableState };
};
