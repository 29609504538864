import type { Operation } from '@apollo/client';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import type { ReactNode } from 'react';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const path: string = import.meta.env.VITE_PC_GQL_PATH || '';
const direct_uri: string = import.meta.env.VITE_APP_URI || '';
const proxy_uri: string = import.meta.env.VITE_VGS_INBOUND_URI || '';
const directLink: ApolloLink = authLink.concat(createHttpLink({ uri: direct_uri + path }));
const proxyLink: ApolloLink = authLink.concat(createHttpLink({ uri: proxy_uri + path }));

const shouldUseProxy = (operation: Operation): boolean => {
  const sensitive: boolean = operation.getContext().containsSensitiveData;
  if (sensitive && proxy_uri) return true;
  if (sensitive)
    console.error(
      'Making PII request without setting REACT_APP_VGS_INBOUND_URI.\nSensitive fields will not be tokenized',
    );
  return false;
};

export const apolloClient = new ApolloClient({
  link: ApolloLink.split(shouldUseProxy, proxyLink, directLink),
  cache: new InMemoryCache(),
});

export const ApolloProviderWrapper = (props: { children: ReactNode }) => {
  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};
