export const routes = {
  login: '/',
  loginCallback: '/login-callback',
  dashboard: '/dashboard',
  creditApplications: '/credit-applications',
  users: '/users',
  collateral: '/collateral',
  customers: '/customers',
  analytics: '/analytics',
  cards: '/cards',
  settings: '/settings',
};
