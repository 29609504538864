import { LoadingSpinner, Typography } from '@pesto/ui';
import { DataList } from '@pesto/ui/components/DataList';
import { isNetworkRequestInFlight } from '@pesto/utils';
import { ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';

import type { CreditApplication } from '../../__generated__/graphql/api';
import { useGetUserByIdQuery } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';
import { CreditCardComponent } from '../CreditCardComponent';

import { ConnectedAddressForm } from './components/ConnectedAddressForm';
import { UserDetailsCard } from './components/UserDetailsCard';
import { UserPersonalInfo } from './components/UserPersonalInfo';
import { UserQuickActionsContainer } from './components/UserQuickActionsContainer/UserQuickActionsContainer';

interface UserDetailsProps {
  userId: string | null | undefined;
}

export const UserDetails = (props: UserDetailsProps) => {
  const { userId } = props;
  const { data, loading, networkStatus } = useGetUserByIdQuery({
    variables: { userId: userId ?? '' },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const user = data?.user;
  const loadingOrReFetching = isNetworkRequestInFlight({ networkStatus: networkStatus, loading: loading });

  if (loadingOrReFetching) {
    return <LoadingSpinner />;
  }
  if (!user) {
    return <div>User not found</div>;
  }
  const creditApplicationRender = (creditApplication: Partial<CreditApplication>) => {
    if (!creditApplication) return null;
    return (
      <UserDetailsCard title={'Credit Application'}>
        <DataList root={creditApplication} />
        <Link
          className={'text-action hover:underline'}
          to={`${routes.creditApplications}/${creditApplication?.id}`}
          target={'_blank'}
        >
          <ExternalLink /> View Application
        </Link>
      </UserDetailsCard>
    );
  };

  const userCards = () => {
    if (!user?.cards?.length) return null;
    return (
      <UserDetailsCard title={'Credit Cards'}>
        {user?.cards?.map(card => {
          if (!card) return null;
          return <CreditCardComponent key={card?.id} card={card} />;
        })}
      </UserDetailsCard>
    );
  };

  return (
    <>
      <div className={'flex flex-col gap-6'}>
        <Typography variant={'headerLarge'}>User Details</Typography>

        <div className={'flex flex-col gap-4 sm:flex-row'}>
          <UserQuickActionsContainer user={user} />
          <UserPersonalInfo userId={user?.id} />
        </div>
        <div className={'flex flex-row flex-wrap gap-4'}>
          {user?.creditApplications?.map((creditApplication, index) => {
            if (!creditApplication || !creditApplication.id) return null;
            return <div key={index + creditApplication.id}>{creditApplicationRender(creditApplication)}</div>;
          })}
          {userCards()}
          <ConnectedAddressForm
            address={user?.billingAddress}
            addressType={'billing'}
            title={'Billing Address'}
            userId={user?.id}
          />
          <ConnectedAddressForm
            address={user?.shippingAddress}
            title={'Shipping Address'}
            addressType={'shipping'}
            userId={user?.id}
          />
          <ConnectedAddressForm
            address={user?.paymentAddress}
            title={'Payment Address'}
            addressType={'payment'}
            userId={user?.id}
          />
        </div>
      </div>
    </>
  );
};
